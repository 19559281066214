<template>
	<div class="p-grid dashboard">
        <div class="p-col-12">
			<div class="card">
                <Toast/>
                <Toolbar class="p-mb-4">
					<template v-slot:left>
                        <h4>Nueva Muestra</h4>  
					</template>
					<template v-slot:right>
                        <Button label="Solicitar Descuento..." icon="pi pi-dollar" class="p-button-raised p-button-warning p-mr-2" @click="openNewdescuento"   :disabled="idMu"/>
                        <Button label="Nueva Muestra" icon="pi pi-plus"  @click="NewMuestraDial" class="p-button-raised p-button-success p-mr-2" :disabled="MuestraNewEna" />
                        <Button label="Imprimir Etiquetas" icon="pi pi-print" @click="ImprimirD" :disabled="idMu" class="p-button-raised p-button-secondary p-mr-2" />
						<!-- <Button label="Autorizar Descuento..." icon="pi pi-dollar" class="p-button-raised p-button-warning p-mr-2" @click="openNew"  /> -->
                        <Button label="Guardar Cambios" icon="pi pi-check" @click="SaveSample" class="p-button-raised p-mr-2" />
                        <Button label="Cancelar" icon="pi pi-check" @click="CancelarMuestra" class="p-button-danger p-mr-2" />
					</template>
				</Toolbar>
				              
				<div class="p-fluid p-formgrid p-grid" :modal="true" >
                    <div class="p-grid" >
						<div class="p-field p-col">

                            <div class="p-field p-col-12 p-md-3" @click="FolioV" v-show="idM" >
                                <label for="folio" style="margin-right: 5px;">Folio de la muestra</label>
                                <label id="folio"> {{Id_Muestra}} </label>
                                <!-- <Button label="Buscar" class="p-button-success p-mr-2" @click="GetMuestra" /> -->
                            </div>

                            <div class="p-field p-col-12 p-md-12">
                                <label for="doctor">Doctor *</label>
                                <!-- <AutoComplete placeholder="Nombre del doctor..." id="doctor" @click="SelectDoctor" :options="autoFilteredValueDoctor" :dropdown="true" required="true" :multiple="false" v-model="selectedAutoValueDoctor" 
                                :suggestions="autoFilteredValueDoctor" @complete="searchDoctor($event)" field="nombre" autofocus :class="{'p-invalid': submitted && !selectedAutoValueDoctor}" /> -->
                                 <Dropdown placeholder="Nombre del doctor..." id="doctor" @click="SelectDoctor" :options="autoValue" required="true" v-model="selectedAutoValueDoctor"  :filter="true"
                                 optionLabel="nombre" autofocus :class="{'p-invalid': submitted && !selectedAutoValueDoctor}" ></Dropdown>
                                <small class="p-invalid" v-if="submitted && !selectedAutoValueDoctor">El doctor es requerido</small>
                            </div>

                            <div class="p-formgrid p-grid">
                                <div class="p-field p-col">
                                    <label for="paciente">Paciente *</label>
                                    <Dropdown id="paciente" v-model="dropdownPaciente" @click="SelectPaciente" :options="autoValuePaciente" optionLabel="Name" 
                                    placeholder="Seleccionar un paciente..." required="true" autofocus :class="{'p-invalid': submitted && !dropdownPaciente}" ></Dropdown>
                                    <small class="p-invalid" v-if="submitted && !dropdownPaciente">El paciente es requerido</small>
                                </div>
                                <div class="p-field p-col">
                                    <label for="fechaNacimiento">Fecha de Nacimiento</label>
                                    <InputText v-model="fechaNacimiento" type="text"  :disabled="true"></InputText>
                                </div>
                            </div>

                            <div class="p-field p-col-12 p-md-12">
                                <label for="padecimiento">Padecimientos *</label>
                                <AutoComplete placeholder="Padecimientos..." id="padecimiento" @click="ChangeAnte()" :dropdown="true" :multiple="true" v-model="selectedAutoValuePadecimiento" :suggestions="autoFilteredValuePadecimiento" 
                                @complete="searchPadecimiento($event)" field="nombre"/>
                                  <small class="p-invalid" v-if="submitted && !selectedAutoValuePadecimiento">El paciente es requerido</small>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="antecendente">Antecedentes *</label>
                                <InputText v-model="antecendente" @change="ChangeAnte()" id="antecendente" type="text" :class="{'p-invalid': submitted && !antecendente}" required="true" />
                                 <small class="p-invalid" v-if="submitted && !antecendente">El antecendente es requerido</small>
                            </div>

                            <div class="p-field p-col-6 p-md-6 p-grid">
                                <div class="p-field p-col">
                                    <!-- <label for="antecendente">Fecha deseada de toma *</label> -->
                                     <h6 v-if="clave_estado=='NLE'">Fecha deseada de toma. Sólo de Lun a Vie</h6>
                                    <h6 v-else>Fecha deseada de toma. Sólo (Lun-Mar-Mier)</h6>
                                    <Calendar :showIcon="true" :showButtonBar="true" required="true" dateFormat="dd/mm/yy" v-model="calendarValue"></Calendar>
                                    <small class="p-invalid" v-if=" submitted && !calendarValue">La fecha deseada de toma es requerida</small>
						        </div>
                                <!-- <div class="p-field p-col">
                                    <label for="grande">No. de Etiquetas exteriores</label>
                                    <InputNumber id="grande" inputStyle="text-align: center;" v-model="etiquetasExteriores" integeronly />
                                </div>
                                <div class="p-field p-col" >
                                    <label for="chica">No. de Etiquetas en tubos</label>
                                    <InputNumber  id="chica" inputStyle="text-align: center;" v-model="etiquetasTubos" integeronly />
                                </div> -->
                            </div>

                        </div>
						<div class="p-field p-col">
                            <div class="p-field p-col-12 p-md-12">
                                <label for="servicio">Servicios</label>
                                <DataTable ref="dt" :value="servicios" @click="rowClicked" v-model:selection="selectedServicios" :key="solicitudmuestraKey" dataKey="nombre" :paginator="true" :rows="10" :filters="filters"
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} servicios">
                                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                                    <Column field="nombre" header="Servicio" :sortable="true"></Column>
                                    <Column field="precio" header="Precio" :sortable="true" headerStyle="text-align:right !important"  >
                                    <template #body="slotProps">
                                        <div :class="preciostyle" style="text-align: right">
                                    ${{formatCurrency(slotProps.data.precio)}}
                                        </div>
                                </template></Column>
                                </DataTable>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <div class="highlight-box">                      
                                    <div class="highlight-details ">
                                        <span>Subtotal</span>
                                        <span class="count">${{ formatCurrency (Subtotal) }}</span>
                                    </div>
                                    <div class="highlight-details ">
                                        <span>Descuento</span>
                                        <span class="count">${{formatCurrency(Descuento)}}</span>
                                    </div>
                                    <div class="highlight-details ">
                                        <span>Total</span>
                                        <span class="count revenue">${{formatCurrency(Total)}}</span>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>

					

				</div>
 
                <Dialog v-model:visible="ImprimirDialog" :style="{width: '450px'}" header="Imprimir" :modal="true" class="p-fluid">
                     <div class="p-field p-col-12 p-md-12 p-grid">
                                
                                <div class="p-field p-col">
                                    <label for="grande">No. de Etiquetas exteriores</label>
                                    <InputNumber id="grande" inputStyle="text-align: center;" v-model="etiquetasExteriores" integeronly />
                                </div>
                                <div class="p-field p-col" >
                                    <label for="chica">No. de Etiquetas en tubos</label>
                                    <InputNumber  id="chica" inputStyle="text-align: center;" v-model="etiquetasTubos" integeronly />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-12 p-grid">
								<div class="p-field p-col">
									  <label for="chica">Servicio</label>
									<Dropdown id="paciente" v-model="SelectServicosImprimir" :options="ServicosImprimir" optionLabel="Nombre" style="width:95%"></Dropdown>
								</div>
							</div>

					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialogImprimir"/>
						<Button label="Imprimir" icon="pi pi-check" class="p-button-text" @click="Imprimir" />
					</template>
				</Dialog>

                <Dialog v-model:visible="nuevaMuesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span >¿Desea registrar una nueva muestra?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="hidenuevaDialogNu"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="Newsample" />
					</template>
				</Dialog>

                <Dialog v-model:visible="descuentoDialog" :style="{width: '450px'}" header="Solicitar Descuento" :modal="true" class="p-fluid">
					<div class="p-field">
						<label for="desc">% Descuento *</label>
                        <InputNumber id="desc" v-model="porcdescuento" integeronly required="true" :min="0" :max="100" autofocus :class="{'p-invalid': submitted && !porcdescuento} " />
						<small class="p-invalid" v-if="submitted && !porcdescuento">El descuento es requerido</small>
					</div>

                    <div class="p-field">
						<label for="doctordescuento">Doctor que solicita el descuento *</label>
						<Dropdown id="doctordescuento" name="doctordescuento" v-model="doctordescuento" :options="autoValue.filter(d=> d.socio === 1)" optionLabel="nombre" placeholder="Selecionar un doctor ..."  autofocus :class="{'p-invalid': submitted && !doctordescuento}" />
						<small class="p-invalid" v-if="submitted && !doctordescuento">El doctor es requerido</small>
					</div>

					<div class="p-field">
						<label for="motivodescuento">Motivo del descuento *</label>
						<InputText id="motivodescuento" v-model.trim="motivodescuento" required="true" autofocus :class="{'p-invalid': submitted && !motivodescuento}" />
						<small class="p-invalid" v-if="submitted && !motivodescuento">El motivo de descuento es requerido</small>
					</div>

					<!-- <div class="p-field">
						<label for="comentarios">Comentarios</label>
						<InputText id="comentarios" v-model.trim="comentarios" />
					</div> -->

					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Solicitar Descuento" icon="pi pi-check" class="p-button-text" @click="saveDescuento" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>
<script>
import DoctorService from '../service/DoctorService';
import PacienteService from '../service/PacienteService';
import PadecimientoService from '../service/PadecimientoService';
import ServicioService from '../service/ServicioService';
import SolicitudMuestraService from '../service/SolicitudMuestraService';
import moment from 'moment';

import Cookies from "js-cookie"
window.Cookies = Cookies

import mqtt from 'mqtt'

	export default {
		data() {
			return {
                idM:false,
                MuestraNewEna:false,
                nuevaMuesDialog:false,
                ImprimirDialog:false,
                idMu:true,
                fechaNacimiento: "",
                etiquetasExteriores: 1,
                etiquetasTubos: 1,
                folioMuestra: 0,
                porcdescuento: 0,
                doctordescuento: null,
                Subtotal:0,
                Descuento:0,
                Total:0,
                motivodescuento: null,
                descuentoDialog: false,
                servicios: null,
                padecimientos: null,
                paciente: null,
                antecendente:null,
                selectedServicios: null,
                selectedAutoValueDoctor: null,
                autoFilteredValueDoctor: [],
                selectedAutoValuePadecimiento: null,
                autoFilteredValuePadecimiento: [],
                autoValuePadecimiento: null,
                autoValuePaciente: null,
                calendarValue: null,
                submitted: false,
                solicitudmuestraKey:0,

				dropdownPaciente: null,
				 
                dropdownServicio: null,
                muestrapro:null, 
                SamplePost:[],
                SamplePostMuestra:[],
                SamplePostServicio:[],
                SamplePostDescuento:[],
                muestraGetInsert:null,
                calendarValueFEcha: null, 
                Id_Muestra:0,
                ImpPost:[], 
                useconver:null,
                userdata:null,
                connection: {
				host: 'broker.emqx.io',
                port: 8084,
                // port: 8083,
				endpoint: '/mqtt',
				clean: true, // 
				connectTimeout: 4000, // 
				reconnectPeriod: 4000, // 
				clientId: 'mqttjs_3be2c321',
				username: 'emqx_test',
				password: 'emqx_test',
                },
                subscription: {
                    topic: 'topic/mqttx',
                    qos: 0,
                },
                publish: {
                    topic: 'sgcells/etiqueta',
                    qos: 0,
                    payload: '{ "msg": "Hello, I am browser." }',
                },
                receiveNews: '',
                qosList: [
                    { label: 0, value: 0 },
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                ],
                client: {
                    connected: false,
                },
                subscribeSuccess: false,
                ServicosImprimir:[],
                SelectServicosImprimir:null,
                 clave_estado:null,
			}
        },
        doctorService: null,
        padecimientoService: null,
        servicioService: null,
        solicitudmuestraService:null,
        pacienteservice:null,
		created() {
            this.doctorService = new DoctorService();
            this.padecimientoService = new PadecimientoService();
            this.servicioService = new ServicioService();
            this.solicitudmuestraService = new SolicitudMuestraService();
            this.pacienteservice = new PacienteService();
		},
		mounted() {
            this.doctorService.getDoctores().then(data => this.autoValue = data);
            this.padecimientoService.getPadecimientos().then(data => this.autoValuePadecimiento = data);
            this.servicioService.getServiciosPrecio().then(data =>{
                this.servicios=data;
                //  for(var i in this.servicios){
                //     console.log(this.servicios[i]);
                // }
                 console.log('ds');
                 console.log(data);
            } );           
            this.pacienteservice.getPacientes().then(data => {
                this.autoValuePaciente = data 
                // this.autoValuePaciente.Name=data.apellido_materno;
                //  this.autoValuePaciente.Name11=data.apellido_materno;
                // console.log(this.autoValuePaciente);
                 for(var i in this.autoValuePaciente){
                  this.autoValuePaciente[i].Name=data[i].nombre+" "+data[i].apellido_paterno+" "+data[i].apellido_materno;
                    // console.log(this.servicios[i]);
                }
                });
            
           
            
		},
        methods: {
            FolioV(){
                this.idM=true;
                this.idMu=false;
            },
            SelectDoctor(){
                if(this.selectedAutoValueDoctor !=null){
                   this.clave_estado=this.selectedAutoValueDoctor.clave_estado;
                   this.MuestraNewEna=true;
                }else{
                    this.MuestraNewEna=false;
                }
            },
            SelectPaciente(){
                if(this.dropdownPaciente != null){
                    this.fechaNacimiento=this.computedDatesFormat(this.dropdownPaciente.fecha_nacimiento);
                    this.MuestraNewEna=true;
                }
                else{
                    this.MuestraNewEna=false;
                }              
            },
            formatCurrency(value) {
            // return value.toLocaleString('en-US', {style: 'currency',currency: 'USD'});
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
            Newsample(){
                 
                // folioMuestra: 0,
                this.porcdescuento= 0;
                this.Subtotal=0;
                this.Descuento=0;
                this.Total=0;
                this.motivodescuento= null;
                this.doctordescuento= null;
                this.descuentoDialog= false; 
                this.selectedServicios= null;
                this.selectedAutoValueDoctor= null; 
                this.selectedAutoValuePadecimiento= null;
                this.calendarValue= null;
                this.Id_Muestra=0;
                this.idM=false;
                 this.nuevaMuesDialog=false;
                 document.location.reload();
            },
            SaveSample(){
                var d = new Date();

			    var Fecha1=new Date(d.toISOString().slice(0,10));
                            Fecha1.setDate(Fecha1.getDate()-1);

                this.useconver = Cookies.get("user");
                if(this.useconver != null || this.useconver !="null"){
                    this.userdata=JSON.parse(this.useconver)												 
                }

                if(this.Id_Muestra==0){                

                    if(this.selectedAutoValueDoctor !=null){

                        if(this.calendarValue !=null)
                        {
                            this.calendarValueFEcha=this.calendarValue.toISOString().slice(0,10)
                        }
                        if(this.calendarValueFEcha >=this.computedDates(d))
                        {
                            this.SamplePost.push({
                                    "id_doctor":this.selectedAutoValueDoctor.id_doctor,
                                    "id_paciente":this.dropdownPaciente.id_paciente, 
                                    "fecha_deseada_toma":this.calendarValueFEcha,
                                    "porc_descuento": this.porcdescuento,
                                    "motivo_descuento": "-",
                                    "antecendente": this.antecendente,
                                    "id_usuario_reg":this.userdata[0].id_usuario,
                                    "id_usuario_mod":this.userdata[0].id_usuario,

                            });
        
                            this.solicitudmuestraService.createSolicitudMuestra(this.SamplePost[0]).then(data => {
                                const promise1 = Promise.resolve(data);     

                                promise1.then((data) => {
                                    this.muestraGetInsert=data;
                                    this.Id_Muestra=data.data.id_muestra;
                                    for(var i in this.selectedAutoValuePadecimiento){
                                        this.selectedAutoValuePadecimiento[i]; 
                                        this.SamplePostMuestra.push({
                                            "id_muestra":data.data.id_muestra,
                                            "id_padecimiento":this.selectedAutoValuePadecimiento[i].id_padecimiento, 						 
                                        });                
                                        this.solicitudmuestraService.createSolicitudMuestraPadecimiento(this.SamplePostMuestra[i]).then();
                                        //console.log(this.id_muestra);
                                    }

                                    for(var ei in this.selectedServicios){ 
                                        this.Id_Muestra=data.data.id_muestra;
                                        this.SamplePostServicio.push({
                                            "id_muestra":data.data.id_muestra,
                                            "id_servicio":this.selectedServicios[ei].id_servicio, 
                                            "precio":this.selectedServicios[ei].precio,						 
                                        });                
                                        this.Id_Muestra=this.SamplePostServicio[ei].id_muestra;
                                        console.log(this.Id_Muestra);
                                        console.log(this.SamplePostServicio);
                                        this.solicitudmuestraService.createSolicitudServicio(this.SamplePostServicio[ei]).then();
                                        //console.log(this.id_muestra);
                                        if(this.Id_Muestra >0){
                                            this.FolioV();
                                        }
                                    } 
                                    this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se acaba de registrar una muestra', life: 3000});	
                                    this.MuestraNewEna=false;                     
                                });
                            });
                            this.SamplePost=[];
                        }
                        else
                        {
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La fecha deseada es menor favor de revisar', life: 3000});
                        }
                    }
                
                }else{
                  

                    if(this.calendarValue !=null){
                        this.calendarValueFEcha=this.calendarValue.toISOString().slice(0,10)
                    }
                     this.SamplePost.push({
                        "id_muestra":this.Id_Muestra,
						"id_doctor":this.selectedAutoValueDoctor.id_doctor,
						"id_paciente":this.dropdownPaciente.id_paciente, 
                        "fecha_deseada_toma":this.calendarValueFEcha,
                        "porc_descuento": this.porcdescuento,
                        "motivo_descuento": "-",

                    });
                    
                    this.solicitudmuestraService.updateSolicitudMuestra(this.SamplePost[0]).then();
                }
                

            },

            rowClicked(){ 
                this.Subtotal=0;
                for(var i in this.selectedServicios){
                    console.log(this.selectedServicios)
                    this.Subtotal=this.Subtotal+Number(this.selectedServicios[i].precio);
                }
                this.Descuento=(this.Subtotal*(this.porcdescuento/100)).toFixed(2);
                // this.Descuento.toFixed(1);
                this.Total=this.Subtotal-this.Descuento;
                       
            },
            forceRerender() {
                this.solicitudmuestraKey += 1;                
    	    },
			searchDoctor(event) {
                console.log(event);
				setTimeout(() => {
					if (!event.query.trim().length) {
						this.autoFilteredValueDoctor = [...this.autoValue];
					}
					else {
						this.autoFilteredValueDoctor = this.autoValue.filter((doctor) => {
							return doctor.nombre.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
				}, 50);
            },
            searchPadecimiento(event) {
				setTimeout(() => {
					if (!event.query.trim().length) {
						this.autoFilteredValuePadecimiento = [...this.autoValuePadecimiento];
					}
					else {
						this.autoFilteredValuePadecimiento = this.autoValuePadecimiento.filter((padecimiento) => {
							return padecimiento.nombre.toLowerCase().startsWith(event.query.toLowerCase());
						});
					}
				}, 50);
            },
            openNew() {
                this.submitted = false;
                this.descuentoDialog = true;
            },
            hideDialog() {
			    this.descuentoDialog = false;
			    this.submitted = false;
            },
            saveDescuento() {

                this.useconver = Cookies.get("user");
                if(this.useconver != null || this.useconver !="null"){
                    this.userdata=JSON.parse(this.useconver)												 
                }
                this.submitted = true;
                this.descuentoDialog = false;  
                 this.SamplePostMuestra=[];   
                this.SamplePostMuestra.push({
                    "id_muestra":this.muestraGetInsert.data.id_muestra,
                    "porc_descuento":this.porcdescuento,
                    "motivo_descuento":this.motivodescuento, 
                    "id_doctor_descuento":this.doctordescuento.id_doctor,
                    "id_usuario_mod":this.userdata[0].id_usuario, 
                });          
               this.solicitudmuestraService.updateSolicitudMuestra(this.SamplePostMuestra[0]).then();   
               this.SamplePostMuestra=[];  
               if(this.selectedServicios != null)
               {
                   this.Subtotal=0;
                for(var i in this.selectedServicios){
                    this.Subtotal=this.Subtotal+Number(this.selectedServicios[i].precio);
                }
                this.Descuento=(this.Subtotal*(this.porcdescuento/100)).toFixed(2);
                // this.Descuento.toFixed(1);
                this.Total=this.Subtotal-this.Descuento;
               }          
            },
            Imprimir(){
                
                if(this.Id_Muestra >0){
                    

                    this.publish.payload= this.etiquetasExteriores.toString()+','+this.etiquetasTubos.toString()+','+this.Id_Muestra.toString()+','+
                    this.dropdownPaciente.nombre.toString()+' '+this.dropdownPaciente.apellido_paterno.toString()+' '+this.dropdownPaciente.apellido_materno.toString()+','+
                    this.dropdownPaciente.fecha_nacimiento.toString()+','+this.SelectServicosImprimir.Nombre.toString()+','+this.selectedAutoValueDoctor.nombre.toString();
			
                    // this.ImpPost.push({
                    //     "folioMuestra": this.Id_Muestra,
                    //     "doctor":this.selectedAutoValueDoctor.nombre,
                    //     "paciente": this.dropdownPaciente.nombre+' '+this.dropdownPaciente.apellido_paterno+' '+this.dropdownPaciente.apellido_materno,
                    //     "fechanaciomiento":this.dropdownPaciente.fecha_nacimiento,
                    //     "exteriores": this.etiquetasExteriores,
                    //     "tubos": this.etiquetasTubos,
                    //     "tipo": "solicitud"
                    // });
                    // this.solicitudmuestraService.ImprimirMuestraSolicitud(this.ImpPost[0]).then();
                    // this.ImpPost=[];
                    this.createConnection(); 
				    const { topic, qos, payload } = this.publish
					this.client.publish(topic, payload, qos, error => {
						 
						if (error) {
						console.log('Publish error', error)
						}
                    })
                    this.publish.payload='';
                    this.ImprimirDialog=false;
                    this.etiquetasTubos=1;
                    this.etiquetasExteriores=1;
                    this.SelectServicosImprimir=null;
                }
            },
            computedDatesFormat(value) {
                return moment(value).format('DD/MM/YYYY')
            },
            ImprimirD(){
                this.ImprimirDialog=true;
                this.ServicosImprimir=[];
                for(var i in this.selectedServicios)
                {                         
                    this.ServicosImprimir.push({"Nombre":this.selectedServicios[i].nombre});                       
                }
            },
            hideDialogImprimir(){
                 this.ImprimirDialog=false;
            },
            NewMuestraDial(){
                this.nuevaMuesDialog=true;
            },
            hidenuevaDialogNu(){
                this.nuevaMuesDialog=false;
            },
            computedDates (value) {
                return moment(value).format('YYYY-MM-DD')
            },
            createConnection() {
                const { host, port, endpoint, ...options } = this.connection
               const connectUrl = `wss://${host}:${port}${endpoint}`
                // const connectUrl = `ws://${host}:${port}${endpoint}`
                try {
                    this.client = mqtt.connect(connectUrl, options)
                    
                    console.log(this.client);
                } catch (error) {
                    console.log('mqtt.connect error', error)
                }
                this.client.on('connect', () => {
                    console.log('Connection succeeded!')
                })
                this.client.on('error', error => {
                    console.log('Connection failed', error)
                })
                this.client.on('message', (topic, message) => {
                    this.receiveNews = this.receiveNews.concat(message)
                    console.log(`Received message ${message} from topic ${topic}`)
                })
		    },	
            ChangeAnte(){
                if(this.antecendente.length>0){
                    this.MuestraNewEna=true;
                }else{
                    this.MuestraNewEna=false;
                }

                if(this.selectedAutoValuePadecimiento.length>0){
                     this.MuestraNewEna=true;
                }else{
                    this.MuestraNewEna=false;
                }
            },
            openNewdescuento() { 
			this.submitted = false;
            this.descuentoDialog = true;
            },

            CancelarMuestra(){
                this.porcdescuento= 0;
                this.Subtotal=0;
                this.Descuento=0;
                this.Total=0;
                this.motivodescuento= null;
                this.descuentoDialog= false; 
                this.selectedServicios= null;
                this.selectedAutoValueDoctor= null; 
                this.selectedAutoValuePadecimiento= null;
                this.calendarValue= null;
                this.Id_Muestra=0;
                this.idM=false;
                this.nuevaMuesDialog=false;
                this.antecendente=null;
                this.dropdownPaciente=null;
                this.fechaNacimiento=null;
            }
		}
	}
</script>


<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#chica {
    text-align: center;
}
</style>
